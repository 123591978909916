var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid relative"},[_c('div',{staticClass:"row"},[(_vm.deleting)?_c('div',{staticClass:"loadingOverlay"},[_c('b-spinner',{attrs:{"small":""}})],1):_vm._e(),_c('div',{staticClass:"col file"},[_c('div',{staticClass:"icon",class:_vm.iconColor(_vm.file.ext)},[(
            _vm.file.ext !== 'jpg' && _vm.file.ext !== 'jpeg' && _vm.file.ext !== 'png'
          )?_c('svg',{attrs:{"viewBox":"0 0 100 100"},domProps:{"innerHTML":_vm._s(_vm.fileIcon(_vm.file.ext))}}):_c('img',{attrs:{"src":_vm.BASE_URL + _vm.file.path,"alt":"Image"}})]),_c('div',{staticClass:"information"},[_c('div',{staticClass:"left-side"},[_c('b',{staticClass:"file-name"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.file.name,26)))]),_c('p',{staticClass:"extension"},[_vm._v(_vm._s(_vm.file.ext))])]),(!_vm.edit && !_vm.serviceDetails)?_c('div',{staticClass:"right-side noedit"},[_c('a',{staticClass:"right-side noedit",attrs:{"href":_vm.BASE_URL + _vm.file.path,"target":"_blank"}},[_c('i',{staticClass:"fal",class:{
                'fa-eye':
                  _vm.file.ext === 'jpg' ||
                  _vm.file.ext === 'jpeg' ||
                  _vm.file.ext === 'png',
                'fa-download':
                  _vm.file.ext === 'pdf' ||
                  _vm.file.ext === 'docx' ||
                  _vm.file.ext === 'xlsx',
              }})])]):(_vm.serviceDetails)?_c('div',{staticClass:"right-side"},[_c('a',{staticClass:"right-side noedit pt-1",attrs:{"href":_vm.BASE_URL + _vm.file.path,"target":"_blank"}},[_c('i',{staticClass:"fal",class:{
                'fa-eye':
                  _vm.file.ext === 'jpg' ||
                  _vm.file.ext === 'jpeg' ||
                  _vm.file.ext === 'png',
                'fa-download':
                  _vm.file.ext === 'pdf' ||
                  _vm.file.ext === 'docx' ||
                  _vm.file.ext === 'xlsx',
              }})]),_c('div',{staticClass:"cursor-pointer",staticStyle:{"margin-left":"15px"}},[_c('i',{staticClass:"fal fa-trash",on:{"click":function($event){return _vm.deleteFile(_vm.file.id, true)}}})])]):_c('div',{staticClass:"right-side cursor-pointer"},[_c('i',{staticClass:"fal fa-trash",on:{"click":function($event){return _vm.deleteFile(_vm.file.id)}}})])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }